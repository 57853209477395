.improvement {
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;

  .banner {
    position: relative;
    transition: 1s;
    opacity: 1;
    left: 0;
    &.start {
      opacity: 0;
      left: -80px;
    }
    img {
      width: 100%;
      height: auto;
    }
    h2 {
      position: absolute;
      top: 40px;
      width: 100%;
      text-align: center;
    }
  }

  .content {
    display: flex;
    align-items: flex-end;
  }

  .pic {
    width: 50%;
    height: auto;
    margin-top: -234px;
    position: relative;
    opacity: 1;
    transition: 1s;
    left: 0;
    top: 0;
    &.start {
      opacity: 0;
      left: -80px;
      top: -80px;
    }
  }

  .text {
    width: 50%;
    padding: 70px 0 0 94px;
    position: relative;
    opacity: 1;
    transition: 1s;
    right: 0;
    top: 0;
    &.start {
      opacity: 0;
      right: -80px;
      top: -80px;
    }
  }

  .button {
    margin-top: 40px;
  }
}

@media all and (max-width: 1168px) {
  .improvement {
    .text {
      padding: 50px 0 0 40px;
    }
  }
}

@media all and (max-width: 700px) {
  .improvement {
    margin-bottom: 40px;
    .banner {
      img {
        display: none;
      }
      h2 {
        position: static;
        text-align: left;
        font-size: 26px;
        padding: 0 20px;
      }
    }
    .content {
      flex-wrap: wrap;
    }
    .pic {
      width: 100%;
      margin-top: 0;
    }
    .text {
      width: 100%;
      padding: 30px 0 0 0;
    }
  }
}

@media all and (max-width: 420px) {
  .improvement .button {
    width: 100%;
    text-align: center;
  }
}
